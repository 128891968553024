<template>
  <div class="column">
    <div class="top">
      <img
        src="../../assets/img/zhSchool/unpurchased/Icon_Arrow_Back.png"
        @click="back"
      />
      <span class="back" @click="back">返回</span>
    </div>
    <div class="top_bg">
      <span class="title">智慧幼儿园</span>
      <span class="hint"
        >采用数据大屏形式，AI智能识别动作，全面数据分析
        汇总，展现园所实力，丰富幼儿课堂互动趣味，打开
        行业发展新格局，推动幼教行业更进一步发展。</span
      >
      <span class="buy" @click="buy">立即进入</span>
    </div>
    <div class="center_layout">
      <div class="hint_l">
        <img
          class="img"
          src="../../assets/img/zhSchool/unpurchased/Pic_ZS_Title_MBZS.png"
        />
        <span class="text">模板展示</span>
      </div>
    </div>
    <div class="teaching-left-tab-box">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide>
          <div class="swiper-box">
            <img
              src="../../assets/img/zhSchool/unpurchased/Pic_WGM_LB_a.gif"
              mode="cover"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-box">
            <img
              src="../../assets/img/zhSchool/unpurchased/Pic_WGM_LB_b.gif"
              mode="cover"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-box">
            <img
              src="../../assets/img/zhSchool/unpurchased/Pic_WGM_LB_c.gif"
              mode="cover"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-box">
            <img
              src="../../assets/img/zhSchool/unpurchased/Pic_WGM_LB_d.gif"
              mode="cover"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-box">
            <img
              src="../../assets/img/zhSchool/unpurchased/Pic_WGM_LB_e.gif"
              mode="cover"
            />
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <div class="image_layout1">
      <img
        src="../../assets/img/zhSchool/unpurchased/Pic_ZS_YSDT.png"
        class="image1"
      />
      <div class="column3">
        <span class="text_title">园所动态屏</span>
        <span class="text5 m22"
          >园所对外展示宣传屏，配备园所风采、师资力量、教学实力、园所
          数据4个功能区，支持调取本地摄像头插件实时展示，支持手机端
          动态展示</span
        >
      </div>
    </div>
    <div class="image_layout2">
      <div class="column2">
        <span class="text_title">数据管理屏</span>
        <span class="text51 m22"
          >园所教学能力数字宣传屏，配备学习情况、成长情况、积分情况、区
          域排名4个功能区</span
        >
      </div>
      <img
        src="../../assets/img/zhSchool/unpurchased/Pic_ZS_SJGL.png"
        class="image2"
      />
    </div>
    <div class="image_layout3">
      <img
        src="../../assets/img/zhSchool/unpurchased/Pic_ZS_HDKT.png"
        class="image1"
      />
      <div class="column3">
        <span class="text_title">互动课堂屏</span>
        <span class="text5 m22"
          >线上智能互动教学宣传屏，配备上课视频播放、园所互动、班级互
          动、幼儿互动4个功能区，支持AI智能识别互动打分，支持手机端
          动态展示</span
        >
      </div>
    </div>
    <div class="image_layout4">
      <div class="column2">
        <span class="text_title">成长训练屏</span>
        <span class="text51 m22"
          >线上大数据测评宣传屏，配备测试专区、PK专区、运动报告4个功能
          区，支持AI智能识别互动打分，支持手机端动态展示</span
        >
      </div>
      <img
        src="../../assets/img/zhSchool/unpurchased/Pic_ZS_CZXL.png"
        class="image2"
      />
    </div>
    <div class="image_layout5">
      <img
        src="../../assets/img/zhSchool/unpurchased/Pic_ZS_XSCG.png"
        class="image1"
      />
      <div class="column3">
        <span class="text_title">比赛闯关屏</span>
        <span class="text5 m22"
          >比赛闯关游戏宣传屏，共24关，每关3～6个动作，支持AI智能识
          别评分，支持园所PK、班级PK、幼儿PK，支持手机端互动</span
        >
      </div>
    </div>
    <div class="image_layout6">
      <div class="column2">
        <span class="text_title">分屏风格</span>
        <span class="text51 m22"
          >多套风格样式宣传屏（星系系列、森林系列、四季系列等）可选，多
          种展示样式（轮播展示、四分屏展示、主副屏展示等）可选</span
        >
      </div>
      <img
        src="../../assets/img/zhSchool/unpurchased/Pic_ZS_FPFG.png"
        class="image2"
      />
    </div>
    <div class="image_layout7">
      <img
        src="../../assets/img/zhSchool/unpurchased/Pic_ZS_ZXDZ.png"
        class="image1"
      />
      <div class="column3">
        <span class="text_title">专项定制屏</span>
        <span class="text5 m22"
          >根据园所自身需求可任意定制宣传屏，每屏提供4个功能区，提供
          摄像头插件服务，提供AI人工智能运动插件服务</span
        >
      </div>
    </div>
    <div class="image_layout8">
      <div class="column2">
        <span class="text6">硬件设备</span>
        <span class="text8 m22">LED数字显示屏、摄像头设备一套</span>
      </div>
      <img
        src="../../assets/img/zhSchool/unpurchased/Pic_ZS_YJSB.png"
        class="image3"
      />
    </div>
    <div class="bottom"><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备16020119号-3</a></div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    buy() {
      this.$router.push({
        path: "/zh/zhSchoolindex",
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
}
.top {
  height: 72px;
  background: #bb3037;
  display: flex;
  align-items: center;
  padding-left: 244px;
  img{
    width:12px;
    height:auto;
  }
  .back {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
  }
}
.top_bg {
  height: 660px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Pic_Bg_Top.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-size: 64px;
    font-family: YouSheBiaoTiHei;
    font-weight: bold;
    color: #ffffff;
    margin-left: 291px;
  }
  .hint {
    width: 506px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    margin-top: 73px;
    margin-left: 291px;
  }
  .buy {
    width: 236px;
    height: 56px;
    background: #bb3037;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin-top: 94px;
    cursor: pointer;
    margin-left: 291px;
  }
}
.center_layout {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .hint_l {
    width: 301px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .img {
      width: 100%;
      position: absolute;
      top: 12px;
      left: 0px;
    }
    .text {
      position: absolute;
      top: 0px;
      left: 78px;
      font-size: 36px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #001827;
    }
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.teaching-left-tab-box {
  display: flex;
  align-items: center;
  margin-top: 80px;
  height: 798px;
  padding-left: 224px;
  padding-right: 224px;
}
.swiper-slide {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .swiper-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
}
.swiper-button-prev {
  width: 44px;
  height: 83px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Icon_Arrow_Left.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.swiper-button-next {
  width: 44px;
  height: 83px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Icon_Arrow_Right.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.image_layout1 {
  width: 100%;
  height: 660px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Pic_Bg_YSDT.png");
  background-size: cover;
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.image_layout2 {
  width: 100%;
  height: 660px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Pic_Bg_SJGL.png");
  background-size: cover;
  display: flex;
  align-items: center;
}
.image_layout3 {
  width: 100%;
  height: 660px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Pic_Bg_HDKT.png");
  background-size: cover;
  display: flex;
  align-items: center;
}
.image_layout4 {
  width: 100%;
  height: 660px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Pic_Bg_CZXL.png");
  background-size: cover;
  display: flex;
  align-items: center;
}
.image_layout5 {
  width: 100%;
  height: 660px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Pic_Bg_XSCG.png");
  background-size: cover;
  display: flex;
  align-items: center;
}
.image_layout6 {
  width: 100%;
  height: 660px;
  background-image: url("../../assets/img/zhSchool/unpurchased/Pic_Bg_FPFG.png");
  background-size: cover;
  display: flex;
  align-items: center;
}
.image_layout7 {
  width: 100%;
  height: 660px;
  background: #12131e;
  display: flex;
  align-items: center;
}
.image_layout8 {
  width: 100%;
  height: 640px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
}
.column1 {
  display: flex;
  flex-direction: column;
}
.column3 {
  display: flex;
  flex-direction: column;
  margin-left: 84px;
}
.column2 {
  display: flex;
  flex-direction: column;
  margin-left: 244px;
}
.image1 {
  width: 784px;
  height: 478px;
  margin-left: 227px;
}
.image2 {
  width: 784px;
  height: 478px;
  margin-left: 61px;
}
.image3 {
  width: 784px;
  height: 478px;
  margin-left: 133px;
}
.text_title {
  font-size: 46px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f1f1f1;
}
.text1 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #888888;
}
.text2 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 16px;
}
.text3 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5b899e;
}
.text4 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a75c5c;
}
.text5 {
  width: 580px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
}
.text51 {
  width: 599px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
}
.row_l {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text6 {
  font-size: 46px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.text7 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.text8 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.m43 {
  margin-top: 43px;
}
.m22 {
  margin-top: 22px;
}
.m28 {
  margin-top: 28px;
}
.bottom {
  width: 100%;
  height: 56px;
  background: #303b3a;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bbbbbb;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>